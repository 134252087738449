<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between mb-1">
      <h3 class="is-size-4">{{ site.name }} {{ Naming.CustomFields }}</h3>
      <div class="buttons has-addons is-rounded is-right">
        <router-link
            v-if="isAdmin && $root.hasAbility('edit-site', 'Billow\\Models\\Site')"
            class="button is-cool-blue-darker is-rounded"
            :to="{
              name: 'site-edit',
              params: {
                site: this.site.id
              }}">
          <icon icon="angle-double-left"/>
          <span>{{ Naming.Site }} Edit</span>
        </router-link>
      </div>
    </div>
    <div class="box">
      <div class="is-flex justify-between">
        <div>
          <h3 class="is-size-4 has-text-weight-light">{{ Naming.Site }} {{ Naming.CustomFields }}</h3>
          <p class="has-text-grey mb-1">A reorderable list of this {{ Naming.Site.toLowerCase() }}'s {{ Naming.CustomFields.toLowerCase() }}</p>
        </div>
        <div>
          <router-link
              v-if="$root.hasAbility('create-custom-fields', 'App\\Models\\CustomField')"
              class="button is-primary"
              :to="{
                name: 'site-create-custom-field',
                params: $route.params
              }">
            <icon icon="plus-circle"/>
            <span>Add New {{ Naming.CustomField }}</span>
          </router-link>
          <router-link
              v-if="canOnlyEditSiteCustomFieldValues"
              class="button is-primary ml-1"
              :to="{
                name: 'site-edit',
                params: {
                  site: this.site.id
                }}">
            <icon icon="edit"/>
            <span>Edit {{ Naming.CustomField }} Values</span>
          </router-link>
        </div>
      </div>
      <custom-fields-draggable-box
          :customFields="site.custom_fields"
          class=""
          @refreshParent="refreshTemplate"/>
      <div v-if="$root.hasAbility('assign-custom-field-template-to-sites', 'App\\Models\\CustomFieldTemplate')">
      <hr>
        <div class="is-flex justify-between align-content-start mt-1">
          <div>
            <h3 class="is-size-4 has-text-weight-light">{{ Naming.CustomField }} Templates</h3>
            <p class="has-text-grey mb-1">Select a {{ Naming.CustomField.toLowerCase() }} template to add its {{ Naming.CustomFields.toLowerCase() }} to this {{ Naming.Site.toLowerCase() }}.</p>
          </div>
          <router-link
              v-if="$root.hasAbility('view-custom-field-templates', 'App\\Models\\CustomFieldTemplate')"
              class="button is-cool-blue-darker is-rounded"
              :to="{name: 'custom-field-templates'}">
            <icon icon="angle-double-right"/>
            <span>Go To {{ Naming.CustomField }} Templates</span>
          </router-link>
        </div>
        <div class="columns">
          <div class="column is-6" v-if="filteredTemplates.length">
            <data-selector
                :items="filteredTemplates"
                :prompt-label="`Start typing to add ${Naming.Site.toLowerCase()} template ${Naming.CustomFields.toLowerCase()}`"
                searchable
                label-key="name"
                value-key="id"
                v-model="templateId">
                Add {{ Naming.CustomFields }} from a {{ Naming.Site }} Template
              <action-button
                  :disabled="!templateId"
                  :working="assigning"
                  class="is-primary"
                  slot="right"
                  @click="addTemplate">
                <icon icon="plus-circle"/>
                <span>Add To {{ Naming.Site }}</span>
              </action-button>
            </data-selector>
          </div>
          <div v-else class="column is-12">
            <notification class="is-cool-blue-light has-text-centered p-2 is-marginless">
              <p class="has-text-weight-bold">No {{ Naming.Site }} Templates Created</p>
              <p>You haven’t created any {{ Naming.Site.toLowerCase() }} templates yet.</p>
            </notification>
          </div>
        </div>
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'site-custom-fields',

  data: () => ({
    loading: true,
    assigning: false,
    templateId: '',
  }),

  computed: {
    ...mapGetters('site', ['site']),
    ...mapGetters('customField', ['customFieldTemplates']),
    ...mapGetters('auth', ['user']),
    filteredTemplates() {
      return _.filter(this.customFieldTemplates, function (template) {
        return template?.custom_fields_count > 0
      })
    },
    canOnlyEditSiteCustomFieldValues(){
      return (
        this.hasBothAbilities('edit-site', 'update-site-custom-field-values') &&
        this.hasNeitherAbility('create-custom-fields', 'edit-custom-fields')
      )
    }
  },

  watch: {
    '$route': 'loadSite'
  },

  async created() {
    await Promise.all([
      this.loadSite(),
    ]).then(() => {
      this.$store.dispatch('customField/loadCustomFieldTemplateList')
    })
    this.loading = false
  },

  methods: {
    async loadSite() {
      this.loading = true
      await this.$store.dispatch('site/loadSite', this.$route.params.site).then(() => {
        this.loading = false
      })
    },
    async refreshTemplate() {
      this.loading = true
      await this.$store.dispatch('site/loadSite', this.$route.params.site).then(() => {
        this.loading = false
      })
    },
    addTemplate() {
      this.assigning = true
      let payload = {
        siteId: this.site.id,
        templateId: this.templateId
      }
      this.$store.dispatch('customField/assignCustomFieldTemplate', payload).then((site) => {
        this.$store.commit('site/setSite', site)
        this.templateId = ''
        this.assigning = false
      })
    },
    hasBothAbilities(firstAbilityName, secondAbilityName){
     return _.filter(this.user.abilities,ability =>
              ability.name === firstAbilityName ||
              ability.name === secondAbilityName
          ).length > 1
    },
    hasNeitherAbility(firstAbilityName, secondAbilityName){
     return _.filter(this.user.abilities,ability =>
              ability.name === firstAbilityName ||
              ability.name === secondAbilityName
          ).length < 1
    }
  }
}
</script>
