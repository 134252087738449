<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between mb-1">
      <h3 class="is-size-4">Update {{ Naming.CustomField }}</h3>
      <div class="buttons has-addons">
        <router-link
            class="button is-info is-rounded"
            :to="{
              name: 'site-custom-fields',
              params: this.$route.params
            }"
        >
          <icon icon="angle-double-left"/>
          <span>{{ Naming.CustomFields }}</span>
        </router-link>
      </div>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <text-input
              :error="$root.errors.label"
              :placeholder="`Provide a ${Naming.CustomField} label`"
              :hint="`Provide the ${Naming.CustomField} with a label`"
              required
              v-model="customField.label">
            Field Label
          </text-input>
        </div>
        <div class="column is-4">
          <text-input
              :error="$root.errors.placeholder"
              :placeholder="`Provide a ${Naming.CustomField} prompt`"
              :hint="`Provide the ${Naming.CustomField} with a prompt for capturing the value`"
              v-model="customField.placeholder">
            Field Placeholder
          </text-input>
        </div>
        <div class="column is-4">
          <select-input
              :hint="`Select which data type the ${Naming.CustomField} can accept`"
              required
              label-key="name"
              value-key="input_type"
              :error="$root.errors.inputTypes"
              :items="inputTypes"
              v-model="customField.input_type">
            Input Type
          </select-input>
        </div>
      </div>
      <div class="columns">
        <custom-field-value-input v-if="customField.input_type" :customField="customField" @valueEntered="assignValue"/>
      </div>
      <div class="columns">
        <div class="column is-flex justify-end ">
          <action-button
              v-if="customField.input_type === 'list'"
              :working="working"
              :disabled="!canSave || saving"
              class="is-primary mr-1"
              @click="toEditPredefinedAnswersPage"
              left-icon="edit">
            Edit Predefined Answers
          </action-button>
          <action-button
              :working="saving"
              :disabled="!canSave || working"
              class="is-success"
              @click="backToCustomFields"
              left-icon="save">
            Update {{ Naming.CustomField }}
          </action-button>
          <action-button
              v-if="$root.hasAbility('delete-custom-fields', 'App\\Models\\CustomField')"
              :disabled="working || saving"
              class="button is-danger ml-1"
              @click="deleteCustomField"
          >
            <icon icon="trash"/>
            <span>Delete {{ Naming.CustomField }}</span>
          </action-button>
        </div>
      </div>
    </div>
  </loader>
</template>
<script>
import {mapGetters} from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  name: 'custom-field-edit',

  data: () => ({
    working: false,
    deleting: false,
    loading: true,
    saving: false,
  }),

  computed: {
    ...mapGetters('customField', [
      'customField',
      'inputTypes'
    ]),
    canSave() {
      return this.customField.label
    }
  },

  created() {
    this.loadCustomField()
  },

  methods: {
    async loadCustomField() {
      await this.$store.dispatch('customField/loadCustomField', this.$route.params.customField).then(() => {
        this.loading = false
      })
    },
    async deleteCustomField() {
      this.deleting = true
      if (await this.$confirm({
        title: this.Convert('Delete Custom Field'),
        message: this.Convert('Are you sure you want to delete this custom field?')
      })) {
        this.$store.dispatch('customField/deleteCustomField', this.customField.id).then(() => {
          this.deleting = false
          this.$toast.success(this.Convert('Custom Field Deleted'))
          this.$router.push({
            name: 'site-custom-fields',
            params: this.$route.params
          })
        }).catch(() => {
          this.deleting = false
          this.$toast.error('Something went wrong, please try again.')
        })
      }
    },
    async updateCustomField() {
      this.saving = true
      await this.$store.dispatch('customField/updateCustomField', this.customField)
    },
    toggleRequiredField() {
      this.customField.required = !this.customField.required;
    },
    assignValue(parameters){
      this.customField.value = parameters.value
    },
    backToCustomFields() {
      this.saving = true
      this.updateCustomField().then(() => {
        this.$toast.success(this.Convert('Custom Field Updated'))
        this.$router.push({
          name: 'site-custom-fields',
          params: {
            site: this.$route.params.site,
          }
        })
        this.saving = false
      }).catch(() => {
        this.saving = false
        this.$toast.error('Something went wrong, please try again.')
      })
    },
    toEditPredefinedAnswersPage() {
      this.working = true
      this.updateCustomField().then(() => {
        this.$toast.success(this.Convert('Custom Field Updated'))
        this.$router.push({
          name: 'site-create-predefined-list',
          params: {
            customField: this.customField.id,
            site: this.$route.params.site
          }
        })
        this.working = false
      }).catch(() => {
        this.working = false
        this.$toast.error('Something went wrong, please try again.')
      })
    }
  }
}
</script>