<template>
<div class="box">
  <level :title="Naming.Zones">
    <div slot="right">
      <router-link  v-if="isAdmin" class="button" :to="{
        name: 'zone-create',
        params: {
          site: $route.params.site
        }
      }">
        <icon icon="plus"/>
        <span>{{ Naming.Zone }}</span>
      </router-link>
    </div>
  </level>

  <template v-if="loaded">

  <form>
    <text-input
      classes="is-rounded"
      v-model="filters.search"
      :placeholder="`Search by ${Naming.Zone} name…`"
      left-icon="search"
      :label="false">
      <action-button
        @click="runSearch"
        class="is-rounded is-cool-blue-dark"
        slot="right">
        Search
      </action-button>
    </text-input>
  </form>

  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Name</th>
        <th>{{ Naming.AssetGroup }}</th>
        <th>Risk</th>
        <th>{{ Naming.Assets }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="zone in zones.data" :key="zone.id">
        <td>
          <link-zone-manager :zone="zone" v-if="$root.hasAbility('view-zones', 'Billow\\Models\\Site')"/>
          <span v-text="zone.name" class="has-text-black" v-else></span>
        </td>
        <td>
          <span v-if="zone.type">
            {{ zone.type.description }}
          </span>
        </td>
        <td>
          <span v-if="zone.type">
            {{ zone.type.risk.level }}
          </span>
        </td>
        <td>{{ zone.assets_count }}</td>
      </tr>
    </tbody>
  </table>
  <p class="has-text-centered" v-if="!zones.data.length">No {{ Naming.Zones }} Found.</p>
  <pager :pageable="zones" @nav="goToPage" :context="Naming.Zone.toLowerCase()"/>

  </template>
  <loader v-else/>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    loaded: false,
    filters: {
      search: ''
    }
  }),

  async created() {
    if(this.$route.query.search) {
      this.filters.search = this.$route.query.search
    }
    await this.loadZones()
    this.loaded = true
  },

  methods: {
    loadZones() {
      return this.$store.dispatch('zone/loadZonesBySite', {
        path: 'api' + this.$route.fullPath,
        params: this.filters
      })
    },
    runSearch() {
      this.$router.push({
        name: 'site-zones',
        params: {
          site: this.$route.params.site
        },
        query: this.filters
      })
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },

  computed: {
    ...mapGetters('zone', [
      'zones'
    ])
  },

  watch: {
    '$route': 'loadZones'
  }

}
</script>
