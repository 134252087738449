<template>
<div>
  <div class="box">
    <level :title="`Deleted ${Naming.Jobs}`">
    </level>
    <job-filters 
    :with-client="false"
    :with-site="false"
    @updated="appliedFilters = $event"/>
  </div>

  <template v-if="loaded">
    <div class="box" v-if="jobs.data.length > 0">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Reference</th>
            <th>Type</th>
            <th>{{ Naming.Technician }}</th>
            <th>Created</th>
            <th>Deleted</th>
            <th>Deleted By</th>
            <th class="has-text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in jobs.data" :key="job.id">
            <td>
              {{ `#${job.number}` }}
              <br>
              <span v-if="job.reference">Reference: <strong>{{ job.reference }}</strong></span>
            </td>
            <td>{{ job.type.description }}</td>
            <td>{{ job.technician.full_name }}</td>
            <td>{{ job.created_at | date('L') }}</td>
            <td>{{ job.deleted_at | date('L') }}</td>
            <td>{{ getJobDeletedBy(job) }}</td>
            <td class="has-text-right">
              <action-button :disabled="recovering" class="is-small is-rounded" @click="recoverJob(job)">
                Recover {{ Naming.Job }}
              </action-button>
            </td>
          </tr>
        </tbody>
      </table>
      <pager :pageable="jobs" @nav="goToPage" :context="Naming.Job.toLowerCase()"/>
    </div>
    <div class="box" v-else>
      <p class="has-text-grey is-size-5">There are no deleted {{ Naming.Jobs.toLowerCase() }} for this {{ Naming.Site.toLowerCase() }}.</p>
    </div>
  </template>
  <loader v-else />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobFilters from '@/views/job/partials/Filters'
import {filter, last} from 'lodash'

export default {

  components: {
    JobFilters
  },

  data: () => ({
    loaded: false,
    exporting: false,
    recovering: false,
  }),

  async created() {
    await this.loadJobs()
    this.loaded = true
  },

  methods: {
    loadJobs() {
      return this.$store.dispatch('job/loadDeletedJobs', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    recoverJob(job) {
      this.recovering = true
      this.$toast.info(this.Convert('Job recovery started'))
      this.$store.dispatch('job/recoverJob', job.id).then(() => {
        this.recovering = false
        this.$toast.success(this.Convert('Job successfully recovered'))
        this.$router.push({
          name: 'job-handler',
          params: {
            job: job.id
          }
        })
      }).catch(error => {
        this.recovering = false
        this.$toast.success('Whoops something went wrong')
      })
    },
    getJobDeletedBy(job) {
      let deletedAuditEvents = filter(job.audits, audit => audit.event === 'deleted')

      if (deletedAuditEvents.length === 0) {
        return '--'
      }
      return last(deletedAuditEvents).user?.full_name ?? '--'
    }
  },

  computed: {
    ...mapGetters('job', [
      'jobs'
    ]),
  },

  watch: {
    '$route': 'loadJobs'
  }

}
</script>
