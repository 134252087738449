<template>
  <div>
    <div class="box is-flex mb-1" v-if="!loading">
      <div class="flex-grow grid gap-1 has-5-columns">
        <text-input
            class="is-marginless"
            v-model="filters.job_number"
            @input="runFilters"
            :placeholder="`${Naming.Job} No.`"
            left-icon="hashtag"
            :label="false">
          <action-button
              v-if="filters.job_number"
              slot="right"
              class="is-light"
              @click="clearFilter('job_number')">
            <icon icon="times"/>
          </action-button>
        </text-input>

        <text-input
            class="is-marginless"
            v-model="filters.search"
            @input="runFilters"
            left-icon="barcode"
            :placeholder="`${Naming.Asset} Code / Barcode`"
            :label="false">
          <action-button
              v-if="filters.search"
              slot="right"
              class="is-light"
              @click="clearFilter('search')">
            <icon icon="times"/>
          </action-button>
        </text-input>

        <data-selector
            class="is-marginless"
            searchable
            v-model="filters.technician_id"
            @input="runFilters"
            left-icon="user"
            label-key="full_name"
            value-key="id"
            :items="techniciansList"
            :prompt-label="`Filter by ${Naming.Technician}`"
            :label="false">
          <action-button
              v-if="filters.technician_id"
              slot="right"
              class="is-light"
              @click="clearFilter('technician_id')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
            searchable
            class="is-marginless"
            left-icon="check"
            v-model="filters.checklist"
            value-key="id"
            label-key="name"
            :prompt-label="`Filter by ${Naming.Checklist}`"
            :items="checklistList"
            @input="runFilters">
          <action-button
              v-if="filters.checklist"
              slot="right"
              @click="clearFilter('checklist')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
            class="is-marginless"
            v-model="filters.status"
            @input="runFilters"
            left-icon="circle-notch"
            :items="statuses"
            prompt-label="Filter by outcome/status"
            :label="false">
          <action-button
              v-if="filters.status"
              slot="right"
              class="is-light"
              @click="clearFilter('status')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <div v-show="advanced">
          <date-picker
              class="is-marginless"
              placeholder="Filter by date (range)"
              mode="range"
              left-icon="calendar"
              :label="false"
              ref="startDate"
              :value="filters.start_date"
              @input="filterStartDate">
            <action-button
                v-if="filters.start_date"
                slot="right"
                @click="clearFilter('start_date')">
              <icon icon="times"/>
            </action-button>
          </date-picker>
        </div>
      </div>
    </div>

    <div class="box">
      <pager :pageable="inspections" @nav="goToPage"/>
      <table class="table is-fullwidth is-vcentered is-hoverable">
        <thead>
        <tr>
          <th>{{ Naming.Job }} / {{ Naming.Inspection }} / {{ Naming.Technician }}</th>
          <th>{{ Naming.Asset }} &amp; {{ Naming.Checklist }}</th>
          <th class="is-narrow has-text-centered">{{ Naming.Checks }} Answered</th>
          <th class="is-narrow has-text-centered">Location</th>
          <th style="width: 12rem">Outcome</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="inspection in inspections.data" :key="inspection.id">
          <td>
            <div class="is-flex flex-column">
              <div>
                <route name="inspection-evidence"
                       :params="{ asset: inspection.asset.id, inspection: inspection.id }">#{{
                    inspection.id
                  }}
                </route>
                <span class="has-text-grey mlr">on</span>
                <route name="job-handler"
                       :params="{ job: inspection.job.id, site: inspection.job.site_id }">{{ Naming.Job }} {{
                    inspection.job.number
                  }} – {{ inspection.job.type.description }}
                </route>
              </div>
              <span class="has-text-weight-light is-flex align-center mt">
                  <span class="is-inline-flex align-center">
                    <avatar :size="22" :path="inspection.technician.picture_url"
                            :name="inspection.technician.full_name"/>
                    <span class="ml">{{ inspection.technician.full_name }}</span>
                  </span>
                  <span>, {{ inspection.created_at | date('L LTS') }}</span>
                </span>
            </div>
          </td>
          <td>
            <div class="is-flex flex-column">
              <span><span class="has-text-weight-light has-text-primary">Barcode:</span> {{
                  inspection.asset.barcode ? inspection.asset.barcode.data : '—'
                }}</span>
              <span><span class="has-text-weight-light has-text-primary">Internal ID:</span> {{ inspection.asset.code }}</span>
              <span><span class="has-text-weight-light has-text-primary">{{ Naming.Checklist }}:</span> {{
                  inspection.checklist.name
                }}</span>
            </div>
          </td>
          <td class="has-text-centered">
            {{ inspection.answers.length }}/{{ inspection.checklist.checks.length }}
          </td>
          <td class="has-text-centered">
            <icon
                class="has-tooltip-left"
                :data-tooltip="hasLocation(inspection.location) ? 'Has Location Data' : 'No Location Data'"
                :class="hasLocation(inspection.location) ? 'has-text-success' : 'has-text-danger'"
                :icon="hasLocation(inspection.location) ? 'map-marker lg' : 'times-circle lg'"/>
          </td>
          <td>
            <yes-no
                v-if="inspection.passed_at || inspection.failed_at"
                :value="Boolean(inspection.passed_at)"
                yes-text="Passed"
                no-text="Failed"/>
            <inspection-status :inspection="inspection"/>
          </td>
        </tr>
        </tbody>
      </table>

      <pager :pageable="inspections" @nav="goToPage"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {debounce, indexOf} from 'lodash'
import download from 'downloadjs'
import { inspection as backend } from '@/api'

export default {

  data: () => ({
    loading: true,
    techniciansList: [],
    siteId: null,
    going_to_page: false,
    advanced: true,
    pullingReport: false,
    filters: {
      search: '',
      job_number: '',
      technician_id: null,
      status: null,
      checklist: null,
      start_date: '',
      site_id: '',
      page: '',
    },
    statuses: [
      { label: 'Passed', value: 'passed' },
      { label: 'Failed', value: 'failed' },
      { label: 'Awaiting Sync', value: 'not_synced' },
      { label: 'In Progress', value: 'in_progress' },
    ],
  }),

  created() {
    this.filters = { ...this.$route.query }
    this.siteId = this.$route.params.site
    Promise.all([
      this.$store.dispatch('user/loadTechnicianList'),
      this.$store.dispatch('checklist/loadChecklistList'),
      this.$store.dispatch('site/loadSiteList'),
      this.loadInspections(),
    ]).then(() => {
      this.getFilteredTechnicians()
    }).finally(() => {
      this.loading = false
    })
  },

  methods: {
    loadInspections() {
      this.going_to_page = true
      this.loading = true
      this.$store.dispatch('inspection/loadInspections', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
        setTimeout(() => this.going_to_page = false, 100)
      })
    },
    getFilteredTechnicians() {
      let site = this.siteId
      this.techniciansList = this.userList.filter(function(user){
        let siteIds = user.sites.map(site => +site.id)
        if(indexOf(siteIds, +site) > -1){
          return user;
        }
      })
    },
    goToPage(path) {
      this.going_to_page = true
      this.loading = true
      this.$router.push(path)
    },
    hasLocation(location) {
      return location !== null && Object.keys(location).length > 0
    },
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
    runFilters: debounce(function () {
      if (this.loading || this.going_to_page) return
      this.loading = true
      this.$router.push({
        name: 'site-inspections',
        query: this.filters
      })
    }, 500),
    filterStartDate(range) {
      if (this.loading || this.going_to_page) return
      if (!range || range.indexOf(' to ') === -1) return
      this.filters.start_date = range
      this.$refs.startDate.picker.redraw()
      this.runFilters()
    },
    pullReport(type) {
      this.pullingReport = true

      if (type === 'pdf') {
        backend.downloadFilteredIndexReport(
            this.filters,
            response => {
              this.downloadReport(response, 'pdf', 'application/pdf')
            },
            error => {
              this.$whoops()
            }
        ).finally(() => this.pullingReport = false)
      } else if (type === 'xlsx') {
        backend.downloadFilteredIndexReportAsExcel(
            this.filters,
            response => {
              this.downloadReport(response, type, 'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            },
            error => {
              this.$whoops()
            }
        ).finally(() => this.pullingReport = false)
      } else if (type === 'csv') {
        backend.downloadFilteredIndexReportAsCsv(
            this.filters,
            response => {
              this.downloadReport(response, type, 'csv', 'text/csv')
            },
            error => {
              this.$whoops()
            }
        )
      }
    },

    downloadReport(response, fileExtension, responseContentType) {
      this.$toast.success(`Report retrieved. Please save the ${fileExtension.toUpperCase()}.`)

      download(
          response.data,
          `All ${this.Convert('Inspections')} as at ${moment().format('DD-MM-YYYY')}.${fileExtension}`,
          responseContentType
      )
    }
  },

  computed: {
    ...mapGetters('inspection', [
      'inspections'
    ]),
    ...mapGetters('user', [
      'userList'
    ]),
    ...mapGetters('site', [
      'siteList'
    ]),
    ...mapGetters('checklist', [
      'checklistList'
    ]),
  },

  watch: {
    '$route': 'loadInspections'
  }

}
</script>
