<template>
  <page icon="city" :title="`New ${Naming.Site}`" v-bind="{ loading }">

    <div slot="tools">
      <router-link class="button is-rounded is-cool-blue" :to="{ name: 'site-admin-index' }">
        <icon icon="arrow-left"/>
        <span>{{ Naming.Sites }}</span>
      </router-link>
    </div>

    <form>
      <div class="box">
        <div class="columns">
          <div class="column">
            <data-selector
                required
                :value="site.client_id"
                @input="client"
                :error="$root.errors.client_id"
                :items="clientList"
                label-key="legal_name"
                value-key="id">
              {{ Naming.Client }}
              <data-selector-client-slot
                  slot="item"
                  slot-scope="{ item }"
                  :client="item"/>
            </data-selector>
          </div>
          <div class="column">
            <text-input required :error="$root.errors.name" :value="site.name" @input="name">Name</text-input>
          </div>
          <div class="column">
            <text-input :error="$root.errors.legal_name" :value="site.legal_name" @input="legalName">Legal Name
            </text-input>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <phone-num
            :number="site.phone_number" 
            :code="site.dialing_code" 
            @code="dialingCode"
            @number="phoneNumber"/>
          </div>
          <div class="column">
            <text-input :error="$root.errors.registration_number" :value="site.registration_number"
                        @input="registrationNumber">Registration Number
            </text-input>
          </div>
          <div class="column">
            <text-input :error="$root.errors.vat_number" :value="site.vat_number" @input="vatNumber">VAT Number
            </text-input>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <data-selector
                multiple
                label-key="full_name"
                value-key="id"
                :prompt-label="`Select one or more ${Naming.Users.toLowerCase()}`"
                :value="site.users"
                @input="users"
                left-icon="users"
                :description="`Only allow the following ${Naming.Users} to interact with this ${Naming.Site}.`"
                :items="userList">
              {{ Naming.User }} Limits
              <data-selector-user-slot
                  slot="item"
                  slot-scope="{ item }"
                  :user="item"/>
              <action-button
                  v-if="hasUsers"
                  slot="right"
                  left-icon="times"
                  @click="users([])">
                Deselect All
              </action-button>
            </data-selector>
          </div>
          <div class="column is-6">
            <data-selector
                :value="site.site_manager_user_id"
                left-icon="user-tie"
                :prompt-label="`Select a ${Naming.Site.toLowerCase()} manager`"
                classes="has-text-grey"
                :description="`Select a ${Naming.Site.toLowerCase()} manager from the list of admin`"
                :items="adminList"
                label-key="full_name"
                value-key="id"
                @input="siteManager">
              Select {{ Naming.Site }} Manager
              <action-button
                  v-if="site.site_manager_user_id > 0"
                  slot="right"
                  @click="clearSiteManager">
                <icon icon="times"/>
              </action-button>
            </data-selector>
          </div>
        </div>
        <div class="columns">

        </div>

        <!-- <div class="columns">
          <div class="column is-narrow">
            <number-input :error="$root.errors.failure_threshold" :percision="0" :value="site.failure_threshold" @input="failureThreshold">Failure Threshold</number-input>
          </div>
        </div> -->
        <physical-address @input="setAddress" :title="`${Naming.Site} Address`"/>
      </div>
      <div class="columns">
        <div class="column">
          <submit-button
              :disabled="$root.hasAbility('create-sites', 'Billow\\Models\\Site') === false"
              :working="working"
              class="is-success is-rounded"
              @submit="createSite"
              left-icon="check">Create {{ Naming.Site }}
          </submit-button>
        </div>
      </div>
    </form>

  </page>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {

  data: () => ({
    working: false,
    loading: true
  }),

  computed: {
    ...mapGetters('user', [
      'userList',
      'adminList'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('client', [
      'clientList'
    ]),
    hasUsers() {
      return this.site?.users?.length > 0
    },
  },

  beforeCreate() {
    this.$store.commit('site/clear')
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('user/loadUserList'),
      this.$store.dispatch('user/loadAdminSelectList'),
      this.$store.dispatch('client/loadClientList'),
    ])
    this.loading = false
  },

  mounted() {
    if (this.$route.query.client) {
      this.client(this.$route.query.client)
    }
  },

  methods: {
    ...mapMutations('site', [
      'name',
      'legalName',
      'registrationNumber',
      'vatNumber',
      'client',
      'failureThreshold',
      'setAddress',
      'users',
      'siteManager',
      'phoneNumber',
      'dialingCode'
    ]),

    createSite() {
      this.working = true
      this.$store.dispatch('site/create').then(site => {
        this.$toast.success(this.Convert(this.$lang.site.created))
        this.working = false
        this.$router.push({
          name: 'site-overview',
          params: {site: site.id}
        })
      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    },
    clearSiteManager() {
      this.$store.commit('site/clearManager')
      this.site.site_manager_user_id = ''
    }
  },

}
</script>
