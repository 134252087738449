<template>
  <div v-bind="{ loading }" v-if="stats.all.length > 0">
    <div class="box mtb-1 columns">
      <div class="column">
        <div class="columns">
          <div class="column">
            <h4 class="is-size-4">{{ Naming.Inspection }} Stats</h4>
            <p class="has-text-grey">A breakdown of {{ Naming.Inspection.toLowerCase() }} performance over the selected date range.</p>
          </div>
          <div class="column is-3">
            <date-picker
                v-model="range.start"
                :max-date="range.end"
                @input="updateRange">From
            </date-picker>
          </div>
          <div class="column is-3">
            <date-picker
                v-model="range.end"
                :min-date="range.start"
                @input="updateRange">To
            </date-picker>
          </div>
        </div>
        <div id="chart">
          <apexchart type=line height=350 :options="chartOptions" :series="series"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { common as backend } from '@/api'
import { first } from 'lodash'

export default {

  page: {
    title: 'Inspection Stats'
  },

  data: () => ({
    stats: {
      all: [],
      failed: [],
      passed: [],
    },
    loading: true,
    range: {
      start: '',
      end: ''
    }
  }),

  created() {
    if (this.$route.query.start && this.$route.query.end) {
      this.range.start = this.$route.query.start
      this.range.end = this.$route.query.end
    } else {
      this.range.start = moment().subtract(1, 'year').format('Y-MM-DD')
      this.range.end = moment().format('Y-MM-DD')
    }

    this.fetchData()
  },

  methods: {
    fetchData() {
      backend.loadPath({
        path: `/api/site/${this.$route.params.site}/inspections-graph`,
        params: this.range
      }, ({ data }) => {
        this.stats = data
        this.loading = false
      }, error => {})
    },
    updateRange() {
      this.fetchData()
    }
  },

  computed: {
    labels() {
      return this.stats.all.map((stat) => first(Object.keys(stat)))
    },
    allValues() {
      return this.stats.all.map(stat => first(Object.values(stat)))
    },
    failedValues() {
      return this.stats.failed.map(stat => first(Object.values(stat)))
    },
    passedValues() {
      return this.stats.passed.map(stat => first(Object.values(stat)))
    },
    notAssessedValues() {
      return this.stats.not_assessed.map(stat => first(Object.values(stat)))
    },
    chartOptions() {
      return {
        colors: ['#3273dc', '#E91E63', '#23d160', '#fcad03'],
        xaxis: {
          categories: this.labels
        },
        stroke: {
          width: 4,
          curve: 'smooth'
        },
      }
    },
    series() {

      return [
        {
          name: 'All ' + this.$store.state.namingConventions.names['INSPECTIONS'],
          data: this.allValues
        },
        {
          name: 'Failed ' + this.$store.state.namingConventions.names['INSPECTIONS'],
          data: this.failedValues
        },
        {
          name: 'Passed ' + this.$store.state.namingConventions.names['INSPECTIONS'],
          data: this.passedValues
        },
        {
          name: this.$store.state.namingConventions.names['INSPECTIONS'] + ' In Progress',
          data: this.notAssessedValues
        }
      ]
    }
  }

}
</script>
