<template>
<div>
  <div class="box">
    <level :title="Naming.Jobs">
      <div slot="right" v-if="isAdmin">
        <dropdown-wrapper position="bottom-end">
          <action-button
            slot="trigger"
            :working="exporting"
            class="is-primary is-rounded">
            Export
          </action-button>
          <dropdown-item @click="exportToFile('xlsx')">Excel Workbook (XLSX)</dropdown-item>
          <dropdown-item @click="exportToFile('csv')">Comma-Separated Values (CSV)</dropdown-item>
        </dropdown-wrapper>
        <new-job-dropdown :site="$route.params.site"/>
      </div>
    </level>
    <job-filters
    :with-client="false"
    :with-site="false"
    @updated="appliedFilters = $event"/>
  </div>

  <template v-if="loaded">
    <div class="box">
       <div class="column">
          <pager :pageable="jobs" @nav="goToPage" :context="Naming.Job.toLowerCase()"/>
        </div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Reference</th>
            <th>Type</th>
            <th>{{ Naming.Technician }}</th>
            <th>Created</th>
            <th>Closed</th>
            <th class="has-text-right">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="job in jobs.data" :key="job.id">
            <td>
              <link-job-handler class="has-text-link" :job="job"/>
              <br>
              <span v-if="job.reference">Reference: <strong>{{ job.reference }}</strong></span>
            </td>
            <td>{{ job.type.description }}</td>
            <td>{{ job.technician.full_name }}</td>
            <td>{{ job.created_at | date('L') }}</td>
            <td>
              <span v-if="job.completed_at">{{ job.completed_at | date('L') }}</span>
              <span v-else>--</span>
            </td>
            <td class="has-text-right">
              <current-status :status="job.current_status"/>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="columns">
        <div class="column">
          <pager :pageable="jobs" @nav="goToPage" :context="Naming.Job.toLowerCase()"/>
        </div>
        <div class="column is-narrow">
          <router-link
            :to="{
              name: 'site-deleted-jobs',
              params: {
                site: site.id
              }
            }"
            class="is-rounded button is-small">
              <icon icon="trash"/>
              <span>Show Deleted {{ Naming.Jobs }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <loader v-else/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobFilters from '@/views/job/partials/Filters'
import { common as backend } from '@/api'
import download from 'downloadjs'

export default {

  components: {
    JobFilters
  },

  data: () => ({
    loaded: false,
    exporting: false,
  }),

  async created() {
    await this.loadJobs()
    this.loaded = true
  },

  methods: {
    loadJobs() {
      return this.$store.dispatch('job/loadJobs', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    exportToFile(type) {
      this.exporting = true
      backend.downloadFile(
        `/api/site/${this.site.id}/jobs/export/${type}`,
        response => {
          download(
            response.data,
            `${this.site.name} ${this.Convert('Jobs')} - ${this.$options.filters.date(new Date)}.${type}`,
            type === 'xlsx' ? 'application/vnd.ms-excel' : 'text/csv'
          )
          this.exporting = false
        },
        error => {
          console.error(error)
          this.exporting = false
          this.$whoops()
        },
        this.$route.query
      )
    },
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    }
  },

  computed: {
    ...mapGetters('job', [
      'jobs'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('auth', [
      'user',
      'isAdmin'
    ])
  },

  watch: {
    '$route': 'loadJobs'
  }

}
</script>
