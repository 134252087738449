<template>
<div v-if="counts.critical" >
  <div class="is-flex align-center mb">
    <div class="flex-shrink is-flex align-center">
      <div class="has-text-bold progress-label">
        Critical
      </div> 
      <span class="ml">{{ counts.critical.ok_percentage }}%</span>
    </div>
    <div class="flex-grow is-flex align-center pl">
      <progress class="progress is-marginless" :class="{
        'is-success': counts.critical.ok_percentage === 100,
        'is-danger': counts.critical.ok_percentage < 100,
      }" :value="counts.critical.ok_percentage" max="100">{{ counts.critical.ok_percentage }}%</progress>
      <div class="ml">
        <span class="tag" :class="{
          'is-success': counts.critical.ok_percentage === 100,
          'is-danger': counts.critical.ok_percentage < 100,
        }">
          <icon :icon="counts.critical.defective > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.critical.defective }}</span>
        </span>
      </div>
    </div>
  </div>  
  <div class="is-flex align-center mb">
    <div class="flex-shrink is-flex align-center">
      <span class="has-text-bold progress-label">High</span>
      <span class="ml">{{ counts.high.ok_percentage }}%</span>
    </div>
    <div class="flex-grow is-flex align-center pl">
      <progress class="progress is-marginless" :class="{
          'is-success': counts.high.ok_percentage === 100,
          'is-warning': counts.high.ok_percentage < 100,
          'is-danger': counts.high.ok_percentage < 80,
        }" :value="counts.high.ok_percentage" max="100">{{ counts.high.ok_percentage }}%</progress>
      <div class="ml">
        <span class="tag" :class="{
          'is-success': counts.high.ok_percentage === 100,
          'is-warning': counts.high.ok_percentage < 100,
          'is-danger': counts.high.ok_percentage < 80,
        }">
          <icon :icon="counts.high.defective > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.high.defective }}</span>
        </span>
      </div>
    </div>
  </div>  
  <div class="is-flex align-center mb">
    <div class="flex-shrink is-flex align-center">
      <span class="has-text-bold progress-label">Medium</span>
      <span class="ml">{{ counts.medium.ok_percentage }}%</span>
    </div>
    <div class="flex-grow is-flex align-center pl">
      <progress class="progress is-marginless" :class="{
          'is-success': counts.medium.ok_percentage === 100,
          'is-warning': counts.medium.ok_percentage < 100,
          'is-danger': counts.medium.ok_percentage < 80,
        }" :value="counts.medium.ok_percentage" max="100">{{ counts.medium.ok_percentage }}%</progress>
      <div class="ml">
        <span class="tag" :class="{
          'is-success': counts.medium.ok_percentage === 100,
          'is-warning': counts.medium.ok_percentage < 100,
          'is-danger': counts.medium.ok_percentage < 80,
        }">
          <icon :icon="counts.medium.defective > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.medium.defective }}</span>
        </span>
      </div>
    </div>
  </div>  
  <div class="is-flex align-center">
    <div class="flex-shrink is-flex align-center">
      <span class="has-text-bold progress-label">Low</span>
      <span class="ml">{{ counts.low.ok_percentage }}%</span>
    </div>
    <div class="flex-grow is-flex align-center pl">
      <progress class="progress is-marginless" :class="{
          'is-success': counts.low.ok_percentage === 100,
          'is-warning': counts.low.ok_percentage < 100,
          'is-danger': counts.low.ok_percentage < 80,
        }" :value="counts.low.ok_percentage" max="100">{{ counts.low.ok_percentage }}%</progress>
      <div class="ml">
        <span class="tag" :class="{
            'is-success': counts.low.ok_percentage === 100,
            'is-warning': counts.low.ok_percentage < 100,
            'is-danger': counts.low.ok_percentage < 80,
          }">
          <icon :icon="counts.low.defective > 0 ? 'exclamation-triangle' : 'check-circle'"/>
          <span>{{ counts.low.defective }}</span>
        </span>
      </div>
    </div>
  </div>  
</div>
</template>

<script>
export default {
  props: {
    counts: {
      type: Object,
      default: () => ({
        critical: {},
        high: {},
        medium: {},
        low: {},
      })
    }
  }
}
</script>