var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.counts.critical)?_c('div',[_c('div',{staticClass:"is-flex align-center mb"},[_c('div',{staticClass:"flex-shrink is-flex align-center"},[_c('div',{staticClass:"has-text-bold progress-label"},[_vm._v(" Critical ")]),_c('span',{staticClass:"ml"},[_vm._v(_vm._s(_vm.counts.critical.ok_percentage)+"%")])]),_c('div',{staticClass:"flex-grow is-flex align-center pl"},[_c('progress',{staticClass:"progress is-marginless",class:{
        'is-success': _vm.counts.critical.ok_percentage === 100,
        'is-danger': _vm.counts.critical.ok_percentage < 100,
      },attrs:{"max":"100"},domProps:{"value":_vm.counts.critical.ok_percentage}},[_vm._v(_vm._s(_vm.counts.critical.ok_percentage)+"%")]),_c('div',{staticClass:"ml"},[_c('span',{staticClass:"tag",class:{
          'is-success': _vm.counts.critical.ok_percentage === 100,
          'is-danger': _vm.counts.critical.ok_percentage < 100,
        }},[_c('icon',{attrs:{"icon":_vm.counts.critical.defective > 0 ? 'exclamation-triangle' : 'check-circle'}}),_c('span',[_vm._v(_vm._s(_vm.counts.critical.defective))])],1)])])]),_c('div',{staticClass:"is-flex align-center mb"},[_c('div',{staticClass:"flex-shrink is-flex align-center"},[_c('span',{staticClass:"has-text-bold progress-label"},[_vm._v("High")]),_c('span',{staticClass:"ml"},[_vm._v(_vm._s(_vm.counts.high.ok_percentage)+"%")])]),_c('div',{staticClass:"flex-grow is-flex align-center pl"},[_c('progress',{staticClass:"progress is-marginless",class:{
          'is-success': _vm.counts.high.ok_percentage === 100,
          'is-warning': _vm.counts.high.ok_percentage < 100,
          'is-danger': _vm.counts.high.ok_percentage < 80,
        },attrs:{"max":"100"},domProps:{"value":_vm.counts.high.ok_percentage}},[_vm._v(_vm._s(_vm.counts.high.ok_percentage)+"%")]),_c('div',{staticClass:"ml"},[_c('span',{staticClass:"tag",class:{
          'is-success': _vm.counts.high.ok_percentage === 100,
          'is-warning': _vm.counts.high.ok_percentage < 100,
          'is-danger': _vm.counts.high.ok_percentage < 80,
        }},[_c('icon',{attrs:{"icon":_vm.counts.high.defective > 0 ? 'exclamation-triangle' : 'check-circle'}}),_c('span',[_vm._v(_vm._s(_vm.counts.high.defective))])],1)])])]),_c('div',{staticClass:"is-flex align-center mb"},[_c('div',{staticClass:"flex-shrink is-flex align-center"},[_c('span',{staticClass:"has-text-bold progress-label"},[_vm._v("Medium")]),_c('span',{staticClass:"ml"},[_vm._v(_vm._s(_vm.counts.medium.ok_percentage)+"%")])]),_c('div',{staticClass:"flex-grow is-flex align-center pl"},[_c('progress',{staticClass:"progress is-marginless",class:{
          'is-success': _vm.counts.medium.ok_percentage === 100,
          'is-warning': _vm.counts.medium.ok_percentage < 100,
          'is-danger': _vm.counts.medium.ok_percentage < 80,
        },attrs:{"max":"100"},domProps:{"value":_vm.counts.medium.ok_percentage}},[_vm._v(_vm._s(_vm.counts.medium.ok_percentage)+"%")]),_c('div',{staticClass:"ml"},[_c('span',{staticClass:"tag",class:{
          'is-success': _vm.counts.medium.ok_percentage === 100,
          'is-warning': _vm.counts.medium.ok_percentage < 100,
          'is-danger': _vm.counts.medium.ok_percentage < 80,
        }},[_c('icon',{attrs:{"icon":_vm.counts.medium.defective > 0 ? 'exclamation-triangle' : 'check-circle'}}),_c('span',[_vm._v(_vm._s(_vm.counts.medium.defective))])],1)])])]),_c('div',{staticClass:"is-flex align-center"},[_c('div',{staticClass:"flex-shrink is-flex align-center"},[_c('span',{staticClass:"has-text-bold progress-label"},[_vm._v("Low")]),_c('span',{staticClass:"ml"},[_vm._v(_vm._s(_vm.counts.low.ok_percentage)+"%")])]),_c('div',{staticClass:"flex-grow is-flex align-center pl"},[_c('progress',{staticClass:"progress is-marginless",class:{
          'is-success': _vm.counts.low.ok_percentage === 100,
          'is-warning': _vm.counts.low.ok_percentage < 100,
          'is-danger': _vm.counts.low.ok_percentage < 80,
        },attrs:{"max":"100"},domProps:{"value":_vm.counts.low.ok_percentage}},[_vm._v(_vm._s(_vm.counts.low.ok_percentage)+"%")]),_c('div',{staticClass:"ml"},[_c('span',{staticClass:"tag",class:{
            'is-success': _vm.counts.low.ok_percentage === 100,
            'is-warning': _vm.counts.low.ok_percentage < 100,
            'is-danger': _vm.counts.low.ok_percentage < 80,
          }},[_c('icon',{attrs:{"icon":_vm.counts.low.defective > 0 ? 'exclamation-triangle' : 'check-circle'}}),_c('span',[_vm._v(_vm._s(_vm.counts.low.defective))])],1)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }