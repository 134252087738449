<template>
<loader v-bind="{ loading }">
  <div class="box">
    <div class="columns">
      <div class="column">
        <h3 class="title is-4">Decommissioned {{ Naming.Assets }}</h3>
      </div>
      <div class="column is-narrow">
        <dropdown-wrapper position="bottom-end">
          <action-button
            slot="trigger"
            :working="exporting"
            class="is-primary is-rounded">
            Export
          </action-button>
          <dropdown-item @click="exportToFile('xlsx')">Excel Workbook (XLSX)</dropdown-item>
          <dropdown-item @click="exportToFile('csv')">Comma-Separated Values (CSV)</dropdown-item>
        </dropdown-wrapper>
      </div>
    </div>
    <asset-filters :use-site="false" :hidden="[
      'checklist',
      'risk',
      'status',
      'last_inspection'
    ]"/>
  </div>
  <div class="box">
     <pager
      :pageable="assets"
      @nav="goTo"
      :context="Naming.Assets.toLowerCase()"
      jump-controls/>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Code</th>
          <th>Barcode</th>
          <th>Type</th>
          <th>{{ Naming.Zone }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="asset in assets.data" :key="asset.id">
          <td>
            {{ asset.code }}
          </td>
          <td>
            <span v-if="asset.barcode">
              {{ asset.barcode.data }}
            </span>
          </td>
          <td>
            {{ asset.type ? asset.type.name : '--' }}
          </td>
          <td>
            <link-zone-manager v-if="asset.zone" :zone="asset.zone" />
            <p v-else>--</p>
          </td>
          <td class="has-text-right">
            <action-button @click="recover(asset)" class="is-small is-rounded">Recover</action-button>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!assets.data.length">No {{ Naming.Assets }} Found.</p>
    <div class="columns">
      <div class="column">
        <pager
          :pageable="assets"
          @nav="goTo"
          :context="Naming.Assets.toLowerCase()"
          jump-controls
        />
      </div>
    </div>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusMonitor from '@/components/iot/StatusMonitor'
import AssetFilters from '@/components/search/admin/AssetFilters'
import { common as backend } from '@/api'
import download from 'downloadjs'
import { recoverDecommissionedAsset } from '@/modals'

export default {

  components: {
    StatusMonitor,
    AssetFilters
  },

  data: () => ({
    loading: true,
    exporting: false,
    search: ''
  }),

  async created() {
    await this.loadAssets()
    this.loading = false
  },

  methods: {
    loadAssets() {
      return this.$store.dispatch('asset/loadAssetsBySite', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    goTo(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'site-decommissioned-assets',
        query: { search: this.search }
      })
    },
    clearSearch() {
      this.search = ''
      this.$router.push({
        name: 'site-decommissioned-assets',
        query: {}
      })
    },
    isPast(date) {
      return moment(date).isBefore(moment())
    },
    exportToFile(type) {
      this.exporting = true
      backend.downloadFile(
        `/api/site/${this.site.id}/decommissioned-assets/export/${type}`,
        response => {
          download(
            response.data,
            `${this.Convert('Assets')} - ${this.$options.filters.date(new Date)}.${type}`,
            type === 'xlsx' ? 'application/vnd.ms-excel' : 'text/csv'
          )
          this.exporting = false
        },
        error => {
          console.error(error)
          this.exporting = false
          this.$whoops()
        },
        this.$route.query
      )
    },
    recover(asset) {
      recoverDecommissionedAsset(asset).then(() => {
        this.$router.push({
          name: 'asset-overview',
          params: { asset: asset.id }
        })
      })
    }
  },

  computed: {
    ...mapGetters('asset', [
      'assets'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
  },

  watch: {
    '$route': 'loadAssets'
  }

}
</script>
