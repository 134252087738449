<template>
  <div class="columns">
    <div class="column is-8">
      <loader v-bind="{loading}">
        <div v-if="Object.keys(stats).length">
        <div class="columns">
          <div class="column">
            <h4 class="is-size-4">{{ Naming.Site }} Activity</h4>
          </div>
          <div class="p-1">
            <div class="buttons has-addons is-rounded is-right">
              <action-button
                  @click="fetchData('all')"
                  :class="{'is-primary': duration === 'all'}"
                  class="button is-rounded">
                All
              </action-button>
              <action-button
                  @click="fetchData('month')"
                  :class="{'is-primary': duration === 'month'}"
                  class="button">
                Last 30 days
              </action-button>
              <action-button
                  @click="fetchData('week')"
                  :class="{'is-primary': duration === 'week'}"
                  class="button">
                Last 7 days
              </action-button>
              <action-button
                  @click="fetchData('day')"
                  :class="{'is-primary': duration === 'day'}"
                  class="button is-rounded">
                Today
              </action-button>
            </div>
          </div>
        </div>
        <loader :loading="loading">
          <div class="columns">
            <div class="column">
              <div class="columns mt-1 is-multiline">
                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.assets_count">
                      {{ stats.assets_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Assets }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.zones_count">
                      {{ stats.zones_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Zones }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.jobs_count">
                      {{ stats.jobs_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Jobs }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.technicians_count">
                      {{ stats.technicians_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Technicians }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.inspections_count">
                      {{ stats.inspections_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Inspections }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.passed_inspections_count">
                      {{ stats.passed_inspections_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      Passed {{ Naming.Inspections }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.failed_inspections_count">
                      {{ stats.failed_inspections_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      Failed {{ Naming.Inspections }}
                    </span>
                  </p>
                </div>

                <div class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.in_progress_inspections_count">
                      {{ stats.in_progress_inspections_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      {{ Naming.Inspections }} In Progress
                    </span>
                  </p>
                </div>

                <div @click="goToSiteAssets" class="column is-4 ptb-1">
                  <p class="is-flex lh-1 align-center flex-column">
                    <span class="tooltip has-tooltip-right is-size-2 has-text-weight-bold" :data-tooltip="stats.overdue_inspections_count">
                      {{ stats.overdue_inspections_count | abbreviateCount }}
                    </span>
                    <span class="is-size-6 has-text-weight-light">
                      Overdue for {{ Naming.Inspections }}
                    </span>
                  </p>
                </div>

              </div>
            </div>
            <div class="column is-3 pt-1">
              <div class="is-flex is-flex-column align-items-center justify-start" style="height: 100%">
                <div class="columns">
                  <div class="column">
                    <p class="is-flex lh-1 align-center flex-column mt-1">
                    <span
                        class="is-size-3 has-text-weight-bold"
                        :class="{
                       'has-text-success': stats.ok_assets_percentage > 90,
                       'has-text-warning': stats.ok_assets_percentage < 90,
                       'has-text-danger': stats.ok_assets_percentage < 60,
                     }"
                    >{{ stats.ok_assets_percentage + '%' }}</span>
                      <span class="is-size-5 has-text-weight-light">of {{ Naming.Assets.toLowerCase() }} are ok</span>
                    </p>
                  </div>
                </div>
                <div class="columns align-items-center" v-if="!loadingPassPercentage">
                  <div class="column">
                    <p class="is-flex lh-1 align-center flex-column mt-1">
                    <span
                        class="is-size-3 has-text-weight-bold"
                        :class="{
                       'has-text-success': jobsPassPercentage > 90,
                       'has-text-warning': jobsPassPercentage < 90,
                       'has-text-danger': jobsPassPercentage < 60,
                     }"
                    >{{ jobsPassPercentage + '%' }}</span>
                      <span class="is-size-5 has-text-weight-light">{{ Naming.Job.toLowerCase() }} pass rate</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </loader>
      </div>
      </loader>
    </div>
    <div class="column is-4">
      <message class="mt-1">
        <div class="columns">
          <div class="column">
            <p>
              A breakdown of the
              <strong>
                {{ assetHealth.total_assets_count }}
                <span>{{ Naming.Asset.toLowerCase() }}{{ assetHealth.total_assets_count === 1 ? '' : 's' }}</span>
                at {{ site.name }}
              </strong> categorised by risk level and defective status.
            </p>
          </div>
        </div>
        <health-bars :counts="assetHealth" />
      </message>
    </div>
  </div>
</template>
<script>
import { common as backend } from '@/api'
import HealthBars from "@/views/site/partials/HealthBars.vue";
import {mapGetters} from "vuex";

export default {
  components: {HealthBars},

  page: {
    title: 'Site Stats'
  },

  props: {
    site: {
      type: Object,
      required: true
    },
  },

  data: () => ({
    stats: {},
    loading: true,
    loadingAssetHealth: true,
    loadingPassPercentage: true,
    duration: 'all',
  }),

  created() {
    this.fetchData('all')
    this.fetchAssetHealth()
  },

  methods: {
    fetchData(duration) {
      this.duration = duration
      this.loading = true
      this.fetchJobsPassPercentage(duration)
      backend.loadPath({
        path: `/api/site/${this.$route.params.site}/stats/${duration}`,
        params: this.range
      }, ({ data }) => {
        this.stats = data
        this.loading = false
      }, error => {
        this.$whoops();
        this.loading = false
      })
    },
    fetchAssetHealth(){
      this.$store.dispatch('site/loadAssetHealth',this.$route.params.site).then(() => {
        this.loadingAssetHealth = false
      })
    },
    fetchJobsPassPercentage(duration){
      backend.loadPath({
            path: `/api/site/${this.$route.params.site}/jobs-pass-percentage/${duration}`}, ({ data }) => {
        this.$store.commit('site/setJobsPassPercentage', data)
        this.loadingPassPercentage = false
      }, error => {
        this.$whoops();
        this.loadingPassPercentage = false
      })
    },
    goToSiteAssets(){
      this.$router.push({ name: `overdue-inspection-index`, query: { site: this.$route.params.site} })
    }
  },

  computed: {
    ...mapGetters('site', ['assetHealth', 'jobsPassPercentage']),
  },
}
</script>
