<template>
<div class="assets-grid">
  <div class="columns pointer types is-vcentered">
    <div class="pointer column" @click="showingAssets = !showingAssets">
      <h5>+ Show {{ type.name }} {{ Naming.Assets }}</h5>
    </div>  
    <div class="pointer column is-narrow buttons has-addons" >
      <action-button @click="selectAllAssets" class="is-success is-inverted">Select all</action-button>
      <action-button @click="deselectAllAssets" class="is-danger is-inverted">Deselect all</action-button>
    </div>  
  </div>  
  <div v-if="showingAssets" >
    <div class="columns is-multiline is-vcentered assets-row">
      <div 
        class="column is-2 is- pointer assets truncated box" 
        :class="{'active-asset': assetIsSelected(asset.id)}"
        @click="selectAsset(asset.id)" 
        v-for="asset in type.assets" :key="asset.id">
        {{asset.code}} 
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  
  props: {
    type: {
      type: Object,
      default: () => ({
        assets : []
      })
    },
    selectedAssets: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    showingAssets: false,
  }),

  methods: {
    selectAsset(assetId) {
      this.$emit('asset-was-toggled', assetId)
    },
    assetIsSelected(assetId) {
      return this.selectedAssets.indexOf(assetId) > -1
    },
    selectAllAssets() {
      this.$emit('all-type-assets-were-selected', this.type.assets.map(asset => asset.id))
    },
    deselectAllAssets() {
      this.$emit('all-type-assets-were-deselected', this.type.assets.map(asset => asset.id))
    }
  },
}
</script>