<template>
  <div>
    <div class="box" v-if="$root.hasAbility('site-import-assets', 'Billow\\Models\\Site')">
      <h4 class="is-size-4">{{ Naming.Asset }} Import Tool</h4>

      <div class="mt-3">
        <h4 class="is-size-4">1. Download {{ Naming.Asset }} Import Template</h4>
        <p>
          Download an {{ Naming.AssetType }}'s template and populate it with your new {{ Naming.Assets.toLowerCase() }}.
        </p>
        <div class="mt-2">
          <data-selector
            required
            searchable
            v-model="selectedAssetTypeId"
            :items="assetTypeList"
            :prompt-label="`Select an ${Naming.AssetType}…`"
            label-key="name"
            value-key="id">
            {{ Naming.AssetType }}
          </data-selector>
        </div>
        <action-button
          slot="right"
          class="mt-1 is-info"
          :working="isDownloadingTemplate"
          @click="downloadTemplate"
          left-icon="download"
          :disabled="!selectedAssetTypeId">
          Download Template (.xlsx)
        </action-button>
      </div>
      <div class="mt-3">
        <h4 class="is-size-4">2. Upload {{ Naming.Asset }} Import Template</h4>
        <p>After populating the spreadsheet with your new {{ Naming.Assets.toLowerCase() }}, import them here:</p>
        <div class="mt-2">
          <file-input
            description="Only xlsx files accepted. Max file size: 50Mb"
            required
            :error="$root.errors?.file"
            @select="setFile">
            XLSX Document
          </file-input>
        </div>
        <action-button
          slot="right"
          class="mt-1 is-info"
          :disabled="!importFile"
          :working="isImporting"
          @click="importTemplate"
          left-icon="file-import">
          Upload
        </action-button>
      </div>
    </div>

    <div class="box" v-if="$root.hasAbility('site-failed-checkpoints-activity-report', 'Billow\\Models\\Site')">
      <h4 class="is-size-4">Failed {{ Naming.Check }} Activity Report</h4>

      <div>
        <p>
          Request an email with this {{ Naming.Site.toLowerCase() }}'s Failed {{ Naming.Check }} Activity Report.
        </p>
        <action-button
          slot="right"
          class="mt-1 is-info"
          :working="isMailingActivityReport"
          @click="mailActivityReport"
          left-icon="envelope-open">
          Mail Activity Report
        </action-button>
      </div>
    </div>

    <div class="box">
      <div class="columns top-column">
        <div class="column">
          <h4 class="is-size-4">{{ Naming.Asset }} Progress Report</h4>
          <p class="mb-1">
            Request an excel file with this {{ Naming.Site.toLowerCase() }}'s {{ Naming.Asset.toLowerCase() }} progress report. All {{ Naming.Site.toLowerCase() }} {{ Naming.Assets.toLowerCase() }} are selected by default. Edit the {{ Naming.Asset.toLowerCase() }} selection below.
          </p>
          <action-button
            class="is-info"
            :working="isDownloadingReport"
            :disabled="selectedAssets.length<1"
            @click="downloadProgressReport"
            left-icon="download">
            Download Progress Report (.xslx)
          </action-button>
          <h4 class="show-zones" @click="editAssetSelection()">+ {{ Naming.Asset }} Selection</h4>
        </div>
      </div>
      <div v-if="(showAssetTypes===true)">
        <div v-for="type in progressReportAssets" :key="type.id">
          <asset-selector
            :type="type"
            :selected-assets="selectedAssets"
            @asset-was-toggled="toggleAssetSelection"
            @all-type-assets-were-selected="handleAllAssetsWereSelected"
            @all-type-assets-were-deselected="deselectAllAssets"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { common as common } from '@/api'
import { mapGetters } from 'vuex'
import download from 'downloadjs'
import { keyBy, difference, concat, uniq, flatMap } from 'lodash'
import AssetSelector from '../reporting/progress/AssetSelector.vue'
import { http } from '../../../api/request'

export default {

  components: {
    AssetSelector,
  },

  data: () => ({
    isDownloadingTemplate: false,
    selectedAssetTypeId: null,
    isImporting: false,
    importFile: null,
    isMailingActivityReport: false,
    isDownloadingReport: false,
    showAssetTypes: false,
    selectedAssets: []
  }),

  async created() {
    await this.$store.dispatch('assetType/loadAssetTypeList')
    await this.$store.dispatch('site/loadProgressReportAssets', this.site.id)
    await this.populateAssetsList()
  },

  methods: {
    goToPage(path) {
      this.$router.push(path)
    },

   async downloadTemplate() {
      this.isDownloadingTemplate = true
          
     common.downloadFile(
        `/api/site/${this.site.id}/asset-type/${this.selectedAssetType.id}/assets-import-template/download`,
        (response) => {
          download(
            response.data,
            `${this.selectedAssetTypeId} - ${this.selectedAssetType.name} - ${this.site.name}} - Import ${this.Convert('Assets')}.xlsx`,
            'application/vnd.ms-excel',
          )
          this.isDownloadingTemplate = false
        },
        async (error) => {
          if (error.response.status === 422) {

            const errors = await this.getErrorsFromBlob(error.response.data)

            this.$toast.warning(errors.errors.no_zones[0])
          }
          this.isDownloadingTemplate = false
        },
      )
    },

    setFile(file) {
      this.importFile = file
    },

    importTemplate() {
      this.isImporting = true
      this.$store.dispatch('imports/importAssets',
        {
          siteId: this.site.id,
          assetTypeId: this.importFile.name.substr(0, this.importFile.name.indexOf(' - ')),
          file: this.importFile,
        },
      ).then(response => {
        this.$toast.success(this.Convert(`You'll receive a mail as soon as all your assets have successfully imported.`))
        this.isImporting = false
      })
    },

    mailActivityReport() {
      this.isMailingActivityReport = true

      this.$store.dispatch('site/mailFailedChecksActivityReport', this.site.id).then(() => {
        this.$toast.success(`You'll receive your <b>Activity Report</b> in your mail soon. Please wait a few minutes.`)
        this.isMailingActivityReport = false
      })
    },

    downloadProgressReport() {
      this.isDownloadingReport = true
      return http({
          method: 'post',
          url: `/api/site/${this.site.id}/assets-progress-report`,
          responseType: 'blob',
          data: { asset_ids: this.selectedAssets },
      })
      .then(response => {
        download(
          response.data,
          `${this.Convert('Asset')} Progress Report for ${this.site.name}.xlsx`,
          'application/vnd.ms-excel',
        )
        this.isDownloadingReport = false
      })
      .catch(error => {
        this.$toast.warning('Something went wrong.', 'Please try again or contact support.')
        this.isDownloadingReport = false
      })
    },

    populateAssetsList() {
      this.selectedAssets = flatMap(this.site.zones, zone=>zone.assets.map(asset=>asset.id))
    },

    editAssetSelection() {
      if (this.showAssetTypes) {
        this.showAssetTypes = false
        this.populateAssetsList()
      } else {
        this.selectedAssets = []
        this.showAssetTypes = true
      }
    },

    toggleAssetSelection(assetId) {
      const index = this.selectedAssets.indexOf(assetId)
      if (index > -1) return this.selectedAssets.splice(index, 1)
      this.selectedAssets.push(assetId)
    },

    handleAllAssetsWereSelected(assetIds) {
      this.selectedAssets = uniq(concat(this.selectedAssets, assetIds))
    },

    deselectAllAssets(assetIds) {
      this.selectedAssets = difference(this.selectedAssets, assetIds)
    },

    async getErrorsFromBlob(blob) {
      const fileReader = new FileReader()

      const readFile = (file) => new Promise((resolve, reject) => {
        fileReader.onerror = () => {
        fileReader.abort();
          reject(new Error('Problem parsing file'));
        };

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.readAsText(file);
      })

      const file = await readFile(blob)
      return JSON.parse(file)
    }
  },

  computed: {
    ...mapGetters('site', [
      'progressReportAssets',
      'site',
    ]),

    ...mapGetters('assetType', [
      'assetTypeList',
    ]),

    selectedAssetType() {
      return keyBy(this.assetTypeList, 'id')[this.selectedAssetTypeId]
    },
  },
}
</script>