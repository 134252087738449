<template>
<loader v-bind="{ loading }">
  <div class="box">
    <div class="columns">
      <div class="column">
        <h3 class="title is-4">{{ Naming.Assets }}</h3>
      </div>
      <div class="column is-narrow">
        <dropdown-wrapper position="bottom-end">
          <action-button
            slot="trigger"
            :working="exporting"
            class="is-primary is-rounded">
            Export
          </action-button>
          <dropdown-item @click="exportToFile('xlsx')">Excel Workbook (XLSX)</dropdown-item>
          <dropdown-item @click="exportToFile('csv')">Comma-Separated Values (CSV)</dropdown-item>
        </dropdown-wrapper>
      </div>
    </div>
    <asset-filters :use-site="false"/>
  </div>
  <div class="box">
     <pager
      :pageable="assets"
      @nav="goTo"
      :context="Naming.Assets.toLowerCase()"
      jump-controls/>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>{{ Naming.Asset }} / Type</th>
          <th>Barcode</th>
          <th>{{ Naming.Zone }}</th>
          <th>Last {{ Naming.Inspection }}</th>
          <th>Next {{ Naming.Inspection }}</th>
          <th>Risk Level</th>
          <th>Dynamic Statuses</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="asset in assets.data" :key="asset.id">
          <td>
            <link-asset-manager class="has-text-link" :asset="asset" :site-id="$route.params.site"/><br>
            {{ asset.type ? asset.type.name : '--' }}
          </td>
          <td>
            <span v-if="asset.barcode">
              {{ asset.barcode.data }}
            </span>
          </td>
          <td>
            <link-zone-manager v-if="asset.zone" :zone="asset.zone" />
            <p v-else>--</p>
          </td>
          <td>
            <latest-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></latest-inspection>
          </td>
          <td>
            <next-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></next-inspection>
          </td>
          <td>
            {{ asset.risk.level }}
          </td>
          <td>
            <active-dynamic-statuses :active-statuses="asset.active_dynamic_statuses"/>
          </td>
          <td>
            <current-status :status="asset.current_status" /><br>
            <component-icon :asset="asset"/>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="has-text-centered" v-if="!assets.data.length">No {{ Naming.Assets }} Found.</p>
    <div class="columns">
      <div class="column">
        <pager
          :pageable="assets"
          @nav="goTo"
          :context="Naming.Assets.toLowerCase()"
          jump-controls
        />
      </div>
      <div class="column is-narrow">
        <router-link
          :to="{
            name: 'site-decommissioned-assets',
            params: {
              site: site.id
            }
          }"
          class="is-rounded button is-small">
            <icon icon="trash"/>
            <span>Show Decommissioned {{ Naming.Assets }}</span>
          </router-link>
        </div>
    </div>
  </div>
</loader>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusMonitor from '@/components/iot/StatusMonitor'
import AssetFilters from '@/components/search/admin/AssetFilters'
import { common as backend } from '@/api'
import download from 'downloadjs'
import ActiveDynamicStatuses from "@/views/asset/partials/ActiveDynamicStatuses.vue";

export default {

  components: {
    ActiveDynamicStatuses,
    StatusMonitor,
    AssetFilters
  },

  data: () => ({
    loading: true,
    exporting: false,
    search: ''
  }),

  async created() {
    await this.loadAssets()
    this.loading = false
  },

  methods: {
    loadAssets() {
      return this.$store.dispatch('asset/loadAssetsBySite', {
        path: '/api' + this.$route.fullPath,
        params: this.$route.query
      })
    },
    goTo(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'site-assets',
        query: { search: this.search }
      })
    },
    clearSearch() {
      this.search = ''
      this.$router.push({
        name: 'site-assets',
        query: {}
      })
    },
    exportToFile(type) {
      this.exporting = true
      backend.downloadFile(
        `/api/site/${this.site.id}/assets/export/${type}`,
        response => {
          download(
            response.data,
            `${this.Convert('Assets')} - ${this.$options.filters.date(new Date)}.${type}`,
            type === 'xlsx' ? 'application/vnd.ms-excel' : 'text/csv'
          )
          this.exporting = false
        },
        error => {
          console.error(error)
          this.exporting = false
          this.$whoops()
        },
        this.$route.query
      )
    },
  },

  computed: {
    ...mapGetters('asset', [
      'assets'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
  },

  watch: {
    '$route': 'loadAssets'
  }

}
</script>
