<template>
<loader v-bind="{ loading }">

  <div class="level">
    <div class="level-left">
      <h3 class="is-size-4 has-text-primary level-item">Documentation</h3>
    </div>
    <div class="level-right">
      <action-button
          :disabled="hasAbility('manage-documentation', 'Billow\\Models\\Site') === false || isClient"
          @click="openUploader"
          class="level-item"
          left-icon="upload">
        Upload Document
      </action-button>
    </div>
  </div>

  <div class="box">
    <table class="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Document</th>
          <th>Uploaded</th>
          <th>Updated</th>
          <th>Expiry Date</th>
          <th class="has-text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="doc in documents.data" :key="doc.id">
          <td>
            <p>{{ doc.title }}</p>
            {{ doc.description }}
          </td>
          <td>
            {{ doc.created_at | date('L') }}
          </td>
          <td>
            {{ doc.updated_at | date('L') }}
          </td>
          <td>
            <span v-if="doc.expires_at">{{ doc.expires_at | date('L') }}</span>
            <span v-else>No expiry date</span>
          </td>
          <td>
            <div class="buttons is-right">
              <action-button :disabled="downloading" @click="download(doc)" left-icon="download" class="is-small">Download</action-button>
              <action-button v-if="isAdmin" :disabled="downloading || $root.hasAbility('manage-documentation', 'Billow\\Models\\Site') === false" @click="uploadDocument(doc)" left-icon="upload" class="is-small is-info">Replace</action-button>
              <action-button v-if="isAdmin" :disabled="downloading || $root.hasAbility('manage-documentation', 'Billow\\Models\\Site') === false" @click="deleteDocument(doc)" left-icon="trash" class="is-small is-danger">Delete</action-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="has-text-centered p-1" v-if="!documents.data.length">
      No Documents Found.
    </p>

    <pager :pageable="documents" context="document" @nav="goToPage"/>
  </div>
</loader>  
</template>

<script>
import { mapGetters } from 'vuex'
import { uploadDocument, replaceDocument } from '@/modals'
import download from 'downloadjs'
import { common as backend } from '@/api'

export default {

  data: () => ({
    loading: true,
    downloading: false
  }), 

  created() {
    this.loadDocuments()
  },

  methods: {
    loadDocuments() {
      this.$store.dispatch('documents/loadDocuments', {
        path: 'api' + this.$route.fullPath
      }).then(() => {
        this.loading = false
      })
    },
    async openUploader() {
      if(await uploadDocument(`/site/${this.$route.params.site}/documents`)) this.loadDocuments()
    },
    async uploadDocument(doc) {
      this.$store.commit('documents/setDocument', doc)
      if(await replaceDocument()) this.loadDocuments()
    },
    goToPage(path) {
      this.$router.push(path)
    },
    download(doc) {
      this.downloading = true
      this.$toast.info('Download Started')
      backend.downloadFile(`/api/documents/${doc.id}/download`, ({data}) => {
        download(data, `${doc.title}.${doc.extension}`, doc.mime)
        this.downloading = false
      }, error => {
        this.downloading = false
      })
    },
    async deleteDocument(doc) {
      if(await this.$confirm({
        title: 'Delete Document',
        message: 'Are you sure you want to delete this document?'
      })) {
        this.$store.dispatch('documents/deleteDocument', doc.id).then(() => {
          this.loadDocuments()
        }).catch(error => {
          this.$whoops()
        })
      }
    }
  },

  computed: {
    ...mapGetters('site', [
      'site',
    ]),
    ...mapGetters('documents', [
      'documents',
    ]),
    ...mapGetters('auth', [
      'isAdmin',
      'isClient',
      'hasAbility'
    ])
  },

  watch: {
    '$route': 'loadDocuments'
  }

}
</script>
