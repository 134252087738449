<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between mb-1">
      <h3 class="is-size-4">Create New {{ Naming.Site }} {{ Naming.CustomField }}</h3>
      <router-link
          class="button is-info is-rounded"
          :to="{
              name: 'site-custom-fields',
              params: this.$route.params
            }">
        <icon icon="angle-double-left"/>
        <span>{{ Naming.CustomFields }}</span>
      </router-link>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <text-input
              :error="$root.errors.label"
              :placeholder="`Provide a ${Naming.CustomField} label`"
              :hint="`Provide the ${Naming.CustomField} with a label`"
              required
              v-model="customField.label">
            Field Label
          </text-input>
        </div>
        <div class="column is-4">
          <text-input
              :error="$root.errors.placeholder"
              :placeholder="`Provide a ${Naming.CustomField} prompt`"
              :hint="`Provide the${Naming.CustomField} with a prompt for capturing the value`"
              v-model="customField.placeholder">
            Field Placeholder
          </text-input>
        </div>
        <div class="column is-4">
          <select-input
              :hint="`Select which data type the ${Naming.CustomField} can accept`"
              required
              label-key="name"
              value-key="input_type"
              :error="$root.errors.input_type"
              :items="inputTypes"
              v-model="customField.input_type">
            Input Type
          </select-input>
        </div>
      </div>
      <div class="columns">
        <custom-field-value-input v-if="customField.input_type" :customField="customField" @valueEntered="assignValue"/>
      </div>
      <div class="columns">
        <div class="column is-flex justify-end">
          <action-button
              :working="saving"
              :disabled="!canSave"
              class="is-success is-rounded"
              @click="saveCustomField"
              left-icon="save">
            Save {{ Naming.CustomField }}
          </action-button>
        </div>
      </div>
    </div>
  </loader>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: 'site-custom-field-create',

  data: () => ({
    customField: {
      label: null,
      placeholder: '',
      required: true,
      input_type: null,
      value: '',
      sort: 0,
      custom_fieldable_type: 'Billow\\Models\\Site',
      custom_fieldable_id: ''
    },
    saving: false,
    loading: true
  }),

  computed: {
    ...mapGetters('customField', [
      'inputTypes',
    ]),
    ...mapGetters('site', [
      'site',
    ]),
    canSave() {
      return !_.filter(this.customField, item => item === null).length;
    }
  },

  async created() {
    this.siteId = this.$route.params.site
    this.customField.custom_fieldable_id = this.siteId
    await this.$store.dispatch('site/loadSite', this.siteId).then(() => {
      this.customField.sort = this.site.custom_fields.length
      this.loading = false
    })
  },

  methods: {
    async saveCustomField() {
      this.saving = true
      this.setDefaultValues()
      await this.$store.dispatch('customField/storeCustomField', this.customField)
          .then((data) => {
            this.$toast.success(this.Convert('Custom field created.'))
            if (this.customField.input_type === 'list') {
              this.goToCreateAnswers(data.id)
            } else {
              this.goBackToTemplate()
            }
          }).catch(() => {
            this.$toast.error('Something went wrong, please try again.')
            this.saving = false
          })
    },
    assignValue(parameters) {
      this.customField.value = parameters.value
    },
    goBackToTemplate() {
      this.$router.push({
        name: 'site-custom-fields',
        params: {
          template: this.custom_fieldable_id,
        }
      })
    },
    goToCreateAnswers(customFieldId) {
      this.$router.push({
        name: 'site-create-predefined-list',
        params: {
          customField: customFieldId,
          site: this.site.id
        }
      })
    },
    setDefaultValues(){
      (/0NaN-aN-aN/ || /undefined/).test(this.customField.value) ? this.customField.value = '' : ''
      if(this.customField.value === '' && this.customField.input_type === 'boolean') {
        this.customField.value = 'Yes'
      }
    }
  }
}

</script>
